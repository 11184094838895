import Hammer from 'hammerjs';
import globals from './globals';
import { Control, FabricObject, FabricText } from 'fabric';

let lastActiveObject: FabricObject | null = null;



function setLastActiveObject() {
	const fabricCanvas = globals.fabricCanvas!;

	const activeObject = fabricCanvas.getActiveObject();
	if (activeObject) {
		lastActiveObject = activeObject;
	}

	// deselect all objects to prevent them from being moved, use timeout to prevent tap from selecting object
	setTimeout(() => {
		fabricCanvas.discardActiveObject();
		fabricCanvas.requestRenderAll();
	}, 0);
}

function setActiveObject() {
	const fabricCanvas = globals.fabricCanvas!;

	if (lastActiveObject) {
		setTimeout(() => {
			fabricCanvas.setActiveObject(lastActiveObject!);
			fabricCanvas.requestRenderAll();
			lastActiveObject = null;
		}, 0);
	}
}

export function initGestureControls(){
	const fabricCanvas = globals.fabricCanvas!;

	// get canvas element from fabricCanvas
	const canvasElement = fabricCanvas.getElement();
	const mc = new Hammer.Manager(document.documentElement);

	// Add Pinch and Rotate recognizers
	const pinch = new Hammer.Pinch();
	const rotate = new Hammer.Rotate();
	pinch.recognizeWith(rotate);
	mc.add([pinch, rotate]);

	let initialScale = 1;
	let initialRotation = 0;
	let initialAngle = 0;
	let initialPosition = { x: 0, y: 0 };


	// Add pinch event listener
	mc.on('pinchstart pinchend pinchmove', (ev) => {
		if (ev.type === 'pinchstart' && !lastActiveObject) {
			setLastActiveObject();
		}

		if(ev.type === 'pinchend') {
			setActiveObject();
			return;
		}

		if (lastActiveObject) {
			if (ev.type === 'pinchstart') {

				initialScale = lastActiveObject.scaleX;
				const pointer = fabricCanvas.getScenePoint(ev.center);
				initialPosition = { x: pointer.x, y: pointer.y };
			}

			// Calculate scale and position
			const newScale = initialScale * ev.scale;
			lastActiveObject.scale(newScale);

			// set object center at the start of pinch
			const pointer = fabricCanvas.getScenePoint(ev.center);
			const dx = pointer.x - initialPosition.x;
			const dy = pointer.y - initialPosition.y;

			// lastActiveObject.set({
			// 	left: lastActiveObject.left + dx,
			// 	top: lastActiveObject.top + dy
			// }).setCoords();

			fabricCanvas.requestRenderAll();

			// Update initial position for smooth dragging
			initialPosition = { x: pointer.x, y: pointer.y };
		}
	});

	// Add rotate event listener
	mc.on('rotatestart rotatemove', (ev) => {
		if (ev.type === 'rotatestart' && !lastActiveObject) {
			setLastActiveObject();
		}

		if (ev.type === 'rotatestart') {
			initialRotation = lastActiveObject!.angle;
			initialAngle = ev.rotation;

			const pointer = fabricCanvas.getScenePoint(ev.center);
			initialPosition = { x: pointer.x, y: pointer.y };
		}

		if (lastActiveObject) {

			// Calculate rotation and position
			const angleOffset = ev.rotation - initialAngle;

			console.log('rotate', angleOffset);

			const newAngle = initialRotation + angleOffset;
			lastActiveObject.rotate(newAngle);

			const pointer = fabricCanvas.getScenePoint(ev.center);
			const dx = pointer.x - initialPosition.x;
			const dy = pointer.y - initialPosition.y;

			// lastActiveObject.set({
			// 	left: lastActiveObject.left + dx,
			// 	top: lastActiveObject.top + dy
			// }).setCoords();

			fabricCanvas.requestRenderAll();

			// Update initial position for smooth dragging
			initialPosition = { x: pointer.x, y: pointer.y };
		}
	});
}
