import * as fabric from 'fabric';
import './styles.css';

import './communication';
import CanvasVideoPlayer from './utils/canvasVideoPlayer';

import events from './events';
import globals from './globals';
import { bakeVideoFrames } from './bakeVideoFrames';
import { encodeFramesToVideo } from './utils/videoEncoder';
import { initGestureControls } from './gestures';
import addEmojiPicker from './tools/emoji';
import addTextCreator from './tools/text';

const canvasContainer:HTMLElement = document.getElementById('canvas-editor')!;
const downloadButton:HTMLElement = document.getElementById('download')!;
const shareButton:HTMLElement = document.getElementById('share')!;

const canvas:HTMLCanvasElement = document.getElementById('fabric-canvas') as HTMLCanvasElement;

const fabricCanvas = new fabric.Canvas(canvas, {
  width: window.innerWidth,
  height: window.innerHeight,
});

globals.fabricCanvas = fabricCanvas;

initGestureControls();
addEmojiPicker();
addTextCreator();

fabricCanvas.on({
  'object:modified': bakeVideoFrames,
  'object:added': bakeVideoFrames,
  'object:removed': bakeVideoFrames,
});

events.on('trimChange', (event) => {
  const {start, end} = event;

  globals.trimStart = start;
  globals.trimEnd = end;

  bakeVideoFrames();
});

events.on('receiveVideo', async (event) => {
	const {frames, fps, width, height} = event;

  globals.videoFrames = frames;

  const aspectRatio = width / height;

  // match fabric canvas to video dimensions
  fabricCanvas.setDimensions({
    width,
    height,
  });

  // make sure the canvas is the right aspect ratio
  const cssHeight = window.innerWidth / aspectRatio;
  canvasContainer.style.width = `${window.innerWidth}px`;
  canvasContainer.style.height = `${cssHeight}px`;

  fabricCanvas.setDimensions({
    width: '100%',
    height: '100%',
  }, {cssOnly: true});

  const canvasPlayer = new CanvasVideoPlayer(
    frames,
    fps,
    width,
    height,
  );

  canvasContainer.appendChild(canvasPlayer.container);

  bakeVideoFrames();

  if (navigator.share) {
    shareButton.onclick = async () => {
      const bakedVideoFrames = await globals.bakedVideoFrames;

      const blob = await encodeFramesToVideo({
        frames: bakedVideoFrames,
        width,
        height,
        fps,
      });

      const file = new File([blob], 'poki.mp4', { type: 'video/mp4' });

      try {
        await navigator.share({
          files: [file],
          title: 'Test Gameplay Video',
          text: 'Check out this game from Poki!',
        });
      } catch (err) {
        console.error('%cPOKI_REPLAY:%c error sharing', 'font-weight: bold', '', err);
      }
    };
  } else {
    shareButton.style.display = 'none';
  }

  downloadButton.onclick = async () => {
    const bakedVideoFrames = await globals.bakedVideoFrames;

    const blob = await encodeFramesToVideo({
      frames: bakedVideoFrames,
      width,
      height,
      fps,
    });

    const file = new File([blob], 'poki.mp4', { type: 'video/mp4' });

    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
});
