import * as fabric from 'fabric';

import globals from "./globals";
import { serializeFrames } from "./utils/colorSpaceSerializer";

const worker = new Worker(
	new URL("./worker/index.ts", import.meta.url),
	{ type: "module" }
);

export function bakeVideoFrames() : void {
	console.info('%cPOKI_REPLAY:%c baking video frames', 'font-weight: bold', '');

	const start = performance.now();

	globals.bakedVideoFrames = new Promise((resolve) => {
		const { fabricCanvas, videoFrames, trimStart, trimEnd } = globals;

		if(!fabricCanvas || !videoFrames.length) {
			return;
		}

		const framesToProcess = videoFrames.slice(trimStart, trimEnd + 1);

		const fabricCanvasElement = fabricCanvas.toCanvasElement();
		const context = fabricCanvasElement.getContext('2d')!;
		const imageData = context.getImageData(0, 0, fabricCanvasElement.width, fabricCanvasElement.height);

		worker.postMessage({
			frames: serializeFrames(framesToProcess),
			imageData,
		});

		worker.onmessage = function(e) {
			const took = performance.now() - start;
			console.info('%cPOKI_REPLAY:%c video baked in ', 'font-weight: bold', '',took.toFixed(2),'ms');

			resolve(e.data);
		}
	});
}
