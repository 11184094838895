import mkvdemuxjs from '../plugins/mkvdemuxjs'
import MP4Box from '../plugins/mp4box'

export async function decodeVideoToFrames(data){
	const start = performance.now();

	const { video, container, codec, fps, width: displayWidth, height: displayHeight } = data;
	const frames = [];

	let codedWidth = displayWidth;
	let codedHeight = displayHeight;
	if (codedWidth % 2 !== 0) {
		codedWidth++;
	}
	if (codedHeight % 2 !== 0) {
		codedHeight++;
	}

	console.info('%cPOKI_REPLAY:%c new video', 'font-weight: bold', '', video.byteLength, container, codec, fps, displayWidth, displayHeight, codedWidth, codedHeight);

	const needResize = displayWidth !== codedWidth || displayHeight !== codedHeight;
	if (needResize) {
		console.info(`%cPOKI_REPLAY:%c resizing video from ${displayWidth}x${displayHeight} to ${codedWidth}x${codedHeight}`, 'font-weight: bold', '');
	}
	let loggedVideoResize = false;

	const canvas = new OffscreenCanvas(codedWidth, codedHeight);
	const ctx = canvas.getContext('2d', { willReadFrequently: true });

	const decoder = new VideoDecoder({
		output: async frame => {
			const frameNeedsResize = frame.displayWidth != displayWidth || frame.displayHeight != displayHeight || frame.codedWidth != codedWidth || frame.codedHeight != codedHeight;
			if (frameNeedsResize && !loggedVideoResize) {
				console.info(`%cPOKI_REPLAY:%c resizing video frame from ${frame.displayWidth}x${frame.displayHeight} (${frame.codedWidth}x${frame.codedHeight}) to ${displayWidth}x${displayHeight} (${codedWidth}x${codedHeight})`, 'font-weight: bold', '');
				loggedVideoResize = true;
			}
			if (needResize || frameNeedsResize) {
				ctx.drawImage(frame, 0, 0, displayWidth, displayHeight);
				const newFrame = new VideoFrame(canvas, {
					timestamp: frame.timestamp,
					duration: frame.duration,
					codedWidth,
					codedHeight,
					displayWidth,
					displayHeight,
				});
				frame.close();
				frame = newFrame;
			}

			let buffer = new Uint8Array(frame.allocationSize());
			let layout = await frame.copyTo(buffer);

			frames.push({
				buffer,
				layout,
				format: frame.format,
				timestamp: frame.timestamp,
				duration: frame.duration,
				codedWidth,
				codedHeight,
				displayWidth,
				displayHeight,
				colorSpace: frame.colorSpace,
			});

			frame.close();
		},
		error: e => {
			console.error(`%cPOKI_REPLAY:%c error decoding video`, 'font-weight: bold', '', e);
		}
	});

	if (container == 'video/webm') {
		decoder.configure({
			codec,
			codedHeight,
			codedWidth,
		});

		let mkvDemuxer = new mkvdemuxjs.MkvDemux();
		mkvDemuxer.push(video);

		const demuxedFrames = [];

		while (true) {
			const part = mkvDemuxer.demux();
			if (!part) {
				break;
			}
			if (part.frames) {
				for (let i = 0; i < part.frames.length; i++) {
					demuxedFrames.push(part.frames[i]);
				}
			}
		}

		let timestampOffset = 0;
		let previousTimestamp = 0;
		for (let i = 0; i < demuxedFrames.length; i++) {
			const frame = demuxedFrames[i];

			if (frame.timestamp === 0) {
				timestampOffset += previousTimestamp;
			}
			previousTimestamp = frame.timestamp;

			frame.timestamp += timestampOffset;
		}

		for (let i = 0; i < demuxedFrames.length; i++) {
			const frame = demuxedFrames[i];
			const chunk = new EncodedVideoChunk({
				type: 'key',
				data: frame.data,
				timestamp: frame.timestamp * 1e6,
				duration: 1e6 / fps,
			});
			decoder.decode(chunk);
		}
	} else if (container === 'video/mp4') {
		const file = MP4Box.createFile();
		file.onError = err => {
			console.error(`%cPOKI_REPLAY:%c error parsing video`, 'font-weight: bold', '', err);
		};
		file.onReady = info => {
			const track = info.videoTracks[0];

			const description = (() => {
				const trak = file.getTrackById(track.id);
				for (const entry of trak.mdia.minf.stbl.stsd.entries) {
					if (entry.avcC || entry.hvcC) {
						const stream = new MP4Box.DataStream(undefined, 0, MP4Box.DataStream.BIG_ENDIAN);
						if (entry.avcC) {
							entry.avcC.write(stream);
						} else {
							entry.hvcC.write(stream);
						}
						return new Uint8Array(stream.buffer, 8);  // Remove the box header.
					}
				}
				throw new Error('avcC or hvcC not found');
			})();

			console.log('track', track);

			const config = {
				codec: track.codec,
				codedWidth,
				codedHeight,
				description,
				info,
			};
			decoder.configure(config);

			file.setExtractionOptions(track.id);
			file.start();
		};
		file.onSamples = (track_id, ref, samples) => {
			for (let i = 0; i < samples.length; i++) {
				const sample = samples[i];
				const chunk = new EncodedVideoChunk({
					type: sample.is_sync ? 'key' : 'delta',
					timestamp: 1e6 * sample.cts / sample.timescale,
					duration: 1e6 * sample.duration / sample.timescale,
					data: sample.data,
				});
				decoder.decode(chunk);
			}
		};

		video.fileStart = 0;
		file.appendBuffer(video);
		file.flush();
	} else {
		throw new Error(`Unsupported container: ${container}`);
	}

	await decoder.flush();
	decoder.close();

	console.info(`%cPOKI_REPLAY:%c decoding took ${Math.round(performance.now() - start)} ms`, 'font-weight: bold', '');

	return frames;
}
