import { FabricText } from 'fabric';

import globals from '../globals';
import { addDeleteControls } from '../utils/fabricDeleteControls';
import './text.css';

const textButton:HTMLElement = document.querySelector('#text-button')!;
const textInput:HTMLInputElement = document.querySelector('#text-input')!;
const textColor:HTMLInputElement = document.querySelector('#text-color')!;
const textFont:HTMLSelectElement = document.querySelector('#text-font')!;
const textBold:HTMLInputElement = document.querySelector('#text-bold')!;
const textWidget:HTMLElement = document.querySelector('#text-widget')!;
const submitButton:HTMLElement = document.querySelector('#text-submit')!;

function styleInput(){
	textInput.style.color = textColor.value;
	textInput.style.fontWeight = textBold.checked ? 'bold' : 'normal';
	textInput.style.fontFamily = textFont.value;
}

textColor.onchange = styleInput;
textFont.onchange = styleInput;
textBold.onchange = styleInput;

function addTextToCanvas(emoji: string) {
	const text = new FabricText(emoji, {
	  left: 100,
	  top: 100,
	  fontSize: 44,
	  fontFamily: textFont.value,
	  originX: 'center',
	  originY: 'center',
	  fill: textColor.value,
	  fontWeight: textBold.checked ? 'bold' : 'normal',
	});

	addDeleteControls(text);

	globals.fabricCanvas!.add(text);
}

function showTextCreator(b:boolean){
	textWidget.style.display = b ? 'flex' : 'none';

	if(b){
		function onClickOutside(e:MouseEvent){
			if(e.target !== textWidget && !textWidget.contains(e.target as Node)){
				showTextCreator(false);
				document.removeEventListener('pointerdown', onClickOutside);
			}
		}
		document.addEventListener('pointerdown', onClickOutside);
	}
}

export default function addTextCreator(){
	showTextCreator(false);

	textButton.addEventListener('click', (e) => {
		showTextCreator(true);
		e.stopPropagation();
	});

	submitButton.addEventListener('click', (e) => {
		const text = textInput.value;

		addTextToCanvas(text);
		showTextCreator(false);

		textInput.value = '';
	});
}
