import './CustomTimeRangeSlider.css'

interface TimeRangeSliderProps {
	frameDuration?: number;
    values?: number[];
    step?: number;
    min?: number;
    max?: number;
    colors?: {
        points?: string | string[];
        rail?: string;
        tracks?: string | string[];
    };
    pointRadius?: number;
    railHeight?: number;
    trackHeight?: number;
}

type ChangeHandler = (values: number[], dragging?: boolean) => void;

export default class TimeRangeSlider {
    private points: HTMLElement[];
	private pointPositions: number[];
	private tooltip: HTMLElement;
	private selectedPointIndex: number;
	private allProps: TimeRangeSliderProps;
	private frameDuration: number;
    private defaultProps: TimeRangeSliderProps;
    private container: HTMLElement;
    private possibleValues: number[];
    private jump: number;
    private rail: HTMLElement;
    private tracks: HTMLElement[];
    private changeHandlers: ChangeHandler[];

	public oldValues: number[];
    public events: Event

    constructor(selector, props: TimeRangeSliderProps = {}) {
        this.defaultProps = {
            values: [25, 75],
            step: 1,
            min: 0,
            max: 100,
            colors: {
                points: "rgb(25, 118, 210)",
                rail: "rgba(25, 118, 210, 0.4)",
                tracks: "rgb(25, 118, 210)"
            },
            pointRadius: 15,
            railHeight: 5,
            trackHeight: 5
        };

        this.allProps = {
            ...this.defaultProps,
            ...props,
            values: [...(props.values ?? this.defaultProps.values ?? [])],
            colors: {
                ...this.defaultProps.colors,
                ...props.colors
            }
        };

        this.container = this.initContainer(selector);
        this.pointPositions = this.generatePointPositions();
        this.possibleValues = this.generatePossibleValues();
        this.jump =
            this.container.offsetWidth /
            Math.ceil(
                (this.allProps.max! - this.allProps.min!) / this.allProps.step!
            );

        this.rail = this.initRail();
        this.tracks = this.initTracks(this.allProps.values!.length - 1);
        this.tooltip = this.initTooltip();
        this.points = this.initPoints(this.allProps.values!.length);

        this.drawScene();

        this.documentMouseupHandler = this.documentMouseupHandler.bind(this);
        this.documentMouseMoveHandler = this.documentMouseMoveHandler.bind(
            this
        );
        this.selectedPointIndex = -1;

        this.changeHandlers = [];

        this.frameDuration = props!.frameDuration!;

		// move the middle point above the other points to make sure its always selectable
        this.points[1].style.zIndex = "1";

		this.oldValues = this.allProps.values!.slice();

        return this;
    }

    drawScene() {
        this.container.classList.add("range-slider__container");
        this.container.appendChild(this.rail);
        this.container.appendChild(this.tooltip);

        this.tracks.forEach(track => this.container.appendChild(track));
        this.points.forEach(point => this.container.appendChild(point));
    }

    generatePointPositions() {
        return this.allProps.values!.map(value => {
            let percentage = (value / this.allProps.max!) * 100;
            return Math.floor((percentage / 100) * this.container.offsetWidth);
        });
    }

    generatePossibleValues() {
        let values:number[] = [];

        for (
            let i = this.allProps.min!;
            i <= this.allProps.max!;
            i += this.allProps.step!
        ) {
            values.push(Math.round(i * 100) / 100);
        }

        if (this.allProps.max! % this.allProps.step! > 0) {
            values.push(Math.round(this.allProps.max! * 100) / 100);
        }

        return values;
    }

    initContainer(selector) {
        const container = document.querySelector(selector);
        container.classList.add("range-slider__container");

        container.style.height = this.allProps.pointRadius! * 2 + "px";

        return container;
    }

    initRail() {
        const rail = document.createElement("span");
        rail.classList.add("range-slider__rail");

        rail.style.background = this.allProps.colors!.rail!;
        rail.style.height = this.allProps.railHeight + "px";
        rail.style.top = this.allProps.pointRadius + "px";

        rail.addEventListener("pointerdown", e => this.railClickHandler(e));

        return rail;
    }

    initTracks(count) {
        let tracks:HTMLSpanElement[] = [];
        for (let i = 0; i < count; i++) {
            tracks.push(this.initTrack(i));
        }

        return tracks;
    }

    initTrack(index) {
        const track = document.createElement("span");
        track.classList.add("range-slider__track");
        let trackPointPositions = this.pointPositions.slice(index, index + 2);

        track.style.left = Math.min(...trackPointPositions) + "px";
        track.style.top = this.allProps.pointRadius + "px";
        track.style.width =
            Math.max(...trackPointPositions) -
            Math.min(...trackPointPositions) +
            "px";
        track.style.height = this.allProps.trackHeight + "px";

        let trackColors = this.allProps.colors!.tracks!;
        track.style.background = Array.isArray(trackColors!)
            ? trackColors[index] || trackColors[trackColors.length - 1]
            : trackColors;

        track.addEventListener("pointerdown", e => this.railClickHandler(e));

        return track;
    }

    initPoints(count) {
        let points:HTMLSpanElement[] = [];
        for (let i = 0; i < count; i++) {
            points.push(this.initPoint(i));
        }

        return points;
    }

    initPoint(index) {
        const point = document.createElement("span");
        point.classList.add("range-slider__point");

        point.style.width = this.allProps.pointRadius! * 2 + "px";
        point.style.height = this.allProps.pointRadius! * 2 + "px";
        point.style.left = `${(this.pointPositions[index] /
            this.container.offsetWidth) *
            100}%`;
        let pointColors = this.allProps.colors!.points!;
        point.style.background = Array.isArray(pointColors)
            ? pointColors[index] || pointColors[pointColors.length - 1]
            : pointColors;

        point.addEventListener("pointerdown", e =>
            this.pointClickHandler(e, index)
        );
        point.addEventListener("pointerover", e =>
            this.pointMouseoverHandler(e, index)
        );
        point.addEventListener("pointerout", e =>
            this.pointMouseOutHandler(e, index)
        );

        return point;
    }

    initTooltip() {
        const tooltip = document.createElement("span");
        tooltip.classList.add("range-slider__tooltip");
        tooltip.style.fontSize = this.allProps.pointRadius + "px";

        return tooltip;
    }

	draw() {
        this.points.forEach((point, i) => {
            point.style.left = `${(this.pointPositions[i] /
                this.container.offsetWidth) *
                100}%`;
        });

        this.tracks.forEach((track, i) => {
            let trackPointPositions = this.pointPositions.slice(i, i + 2);
            track.style.left = Math.min(...trackPointPositions) + "px";
            track.style.width =
                Math.max(...trackPointPositions) -
                Math.min(...trackPointPositions) +
                "px";
        });

        this.tooltip.style.left =
            this.pointPositions[this.selectedPointIndex] + "px";
        this.tooltip.textContent = this.getSelectedPointTime(this.selectedPointIndex);
    }

    railClickHandler(e){
        let oldSelectedPointIndex = this.selectedPointIndex;
        this.selectedPointIndex = 1; // always move the cue on click
        this.documentMouseMoveHandler(e);
        this.selectedPointIndex = oldSelectedPointIndex;

        this.pointClickHandler(e, 1);

        this.draw();
    }

    getClosestPointIndex(mousePosition) {
        let shortestDistance = Infinity;
        let index = 0;
        for (let i in this.pointPositions) {
            let dist = Math.abs(mousePosition - this.pointPositions[i]);
            if (shortestDistance > dist) {
                shortestDistance = dist;
                index = parseInt(i);
            }
        }

        return index;
    }

    documentMouseupHandler() {
        this.changeHandlers.forEach(func => func(this.allProps.values!));
        this.points[this.selectedPointIndex].style.boxShadow = `none`;
        this.selectedPointIndex = -1;
        this.tooltip.style.transform = "translate(-50%, -60%) scale(0)";
        document.removeEventListener("pointerup", this.documentMouseupHandler);
        document.removeEventListener(
            "pointermove",
            this.documentMouseMoveHandler
        );
    }

	documentMouseMoveHandler(e) {
        let newPoisition = this.getMouseRelativePosition(e.pageX);
        let extra = Math.floor(newPoisition % this.jump);
        if (extra > this.jump / 2) {
            newPoisition += this.jump - extra;
        } else {
            newPoisition -= extra;
        }
        if (newPoisition < 0) {
            newPoisition = 0;
        } else if (newPoisition > this.container.offsetWidth) {
            newPoisition = this.container.offsetWidth;
        }

		if(this.selectedPointIndex < this.pointPositions.length - 1) {
			const nextPosition = this.pointPositions[this.selectedPointIndex + 1];
			if(newPoisition >= nextPosition) {
				newPoisition = nextPosition;
			}
		}

		if(this.selectedPointIndex > 0) {
			const previousPosition = this.pointPositions[this.selectedPointIndex - 1];
			if(newPoisition <= previousPosition) {
				newPoisition = previousPosition;
			}
		}

		const previousPosition = this.pointPositions[this.selectedPointIndex];
        this.pointPositions[this.selectedPointIndex] = newPoisition;

		if(previousPosition !== newPoisition) {
			this.changeHandlers.forEach(func => func(this.allProps.values!, true));
		}

        this.allProps.values![this.selectedPointIndex] = this.possibleValues[
            Math.floor(newPoisition / this.jump)
        ];
        this.draw();

        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    pointClickHandler(e, index) {
        e.preventDefault();
        this.selectedPointIndex = index;
        document.addEventListener("pointerup", this.documentMouseupHandler);
        document.addEventListener("pointermove", this.documentMouseMoveHandler);
    }

	pointMouseoverHandler(e, index) {
        const transparentColor = TimeRangeSlider.addTransparencyToColor(
            this.points[index].style.backgroundColor,
            16
        );
        if (this.selectedPointIndex < 0) {
            this.points[index].style.boxShadow = `0px 0px 0px ${Math.floor(
                this.allProps.pointRadius! / 1.5
            )}px ${transparentColor}`;
        }
        this.tooltip.style.transform = "translate(-50%, -60%) scale(1)";
        this.tooltip.style.left = this.pointPositions[index] + "px";

		this.tooltip.textContent = this.getSelectedPointTime(index);
    }

    static addTransparencyToColor(color, percentage) {
        if (color.startsWith("rgba")) {
            return color.replace(/(\d+)(?!.*\d)/, percentage + "%");
        }

        if (color.startsWith("rgb")) {
            let newColor = color.replace(/(\))(?!.*\))/, `, ${percentage}%)`);
            return newColor.replace("rgb", "rgba");
        }

        if (color.startsWith("#")) {
            return color + percentage.toString(16);
        }

        return color;
    }

    pointMouseOutHandler(e, index) {
        if (this.selectedPointIndex < 0) {
            this.points[index].style.boxShadow = `none`;
            this.tooltip.style.transform = "translate(-50%, -60%) scale(0)";
        }
    }

    getMouseRelativePosition(pageX) {
        return pageX - this.container.offsetLeft;
    }

    onChange(func) {
        if (typeof func !== "function") {
            throw new Error("Please provide function as onChange callback");
        }
        this.changeHandlers.push(func);
        return this;
    }

    setPointValue(index:number, value:number) {
		this.allProps.values![index] = value;
		this.pointPositions[index] = value * this.jump;
		this.draw();
	}

    getSelectedPointTime(index:number) {
		const time = this.allProps.values![index] * this.frameDuration;
		const minutes = Math.floor(time / 60000);
		const seconds = ((time % 60000) / 1000).toFixed(0);
		return `${minutes}:${seconds.padStart(2, '0')}`;
	}
}
