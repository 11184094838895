import events from '../events';

import TimeRangeSlider from "./CustomTimeRangeSlider";

import './canvasVideoPlayer.css';

class CanvasVideoPlayer {
	public container: HTMLDivElement;
	private controls: HTMLDivElement;

	private frames: any[];
	private fps: number;
	private frameDuration: number;
	private canvas: HTMLCanvasElement;
	private ctx: CanvasRenderingContext2D;

	private playing: boolean = false;
	private raf:number;
	private currentTimestamp: number = 0;
	private currentFrameIndex: number = -1;
	public trimStart: number = 0;
	public trimEnd: number = 0;

	private playButton: HTMLButtonElement;
	private trimBarSlider: TimeRangeSlider;

	constructor(frames:any[], fps:number, width:number, height:number) {
		this.frames = frames;
		this.fps = fps;
		this.frameDuration = 1000 / fps;
		this.container = document.createElement('div');
		this.container.classList.add('canvas-video-player');

		this.canvas = document.createElement('canvas');
		this.canvas.width = width;
		this.canvas.height = height;
		this.canvas.style.width = '100%';
		this.canvas.style.height = '100%';
		this.ctx = this.canvas.getContext('2d')!;
		this.container.appendChild(this.canvas);

		this.trimEnd = frames.length - 1;

		this.drawFrame(0);

		this.controls = document.createElement('div');
		this.controls.classList.add('canvas-video-player-controls');
		this.container.appendChild(this.controls);
		this.buildControls();
	}

	play() {
		if (this.playing) {
			this.pause();
			return;
		}

		this.playing = true;
		this.playButton.classList.remove('pause');
		let previousTimestamp = 0;
		const renderFrame = (time) => {
			if (!previousTimestamp) {
				previousTimestamp = time;
			}

			const timeDiff = time - previousTimestamp;

			this.currentTimestamp += timeDiff;

			const frameIndex = Math.floor(this.currentTimestamp / this.frameDuration);
			this.trimBarSlider.setPointValue(1, frameIndex);

			if (frameIndex >= this.trimEnd) {
				this.stop();
				return;
			}

			if(frameIndex !== this.currentFrameIndex) {
				this.drawFrame(frameIndex);
			}


			previousTimestamp = time;

			this.raf = requestAnimationFrame(renderFrame);
		};

		renderFrame(0);
	}

	drawFrame(frameIndex) {
		const frame = new VideoFrame(this.frames[frameIndex].buffer, this.frames[frameIndex]);
		this.ctx.drawImage(frame, 0, 0);
		this.currentFrameIndex = frameIndex;

		// close the frame to release the memory
		frame.close();
	}

	pause() {
		this.playing = false;
		this.playButton.classList.add('pause');
		cancelAnimationFrame(this.raf);
		// show play button
	}

	stop() {
		this.pause();
		this.currentTimestamp = this.trimStart * this.frameDuration;
	}

	buildControls() {
		this.playButton = document.createElement('button');
		this.playButton.classList.add('canvas-video-player-play', 'pause');
		this.playButton.addEventListener('click', () => this.play());
		this.controls.appendChild(this.playButton);

		this.container.appendChild(this.controls);

		setTimeout(() => {
			// we need the dom to be ready before we can build the seek bar
			this.buildSeekBar();
		}, 0);
	}

	buildSeekBar(){
		const trimBar = document.createElement('div');
		this.controls.appendChild(trimBar);
		trimBar.classList.add('canvas-video-player-trim-bar');
		this.trimBarSlider = new TimeRangeSlider('.canvas-video-player-trim-bar', {
			values: [0, 0, this.frames.length - 1],
			min: 0,
			max: this.frames.length - 1,
			frameDuration: this.frameDuration,
			railHeight: 5,
			trackHeight: 6,
			pointRadius: 10,
			colors: {
				points: ["#009CFF", "#009CFF", "#009CFF"],
				rail: "#F0F5FC",
				tracks: "#009CFF",
			}
		});

		this.trimBarSlider.onChange((values, down) => {
			const [start, cue, end] = values;

			// if values is equal to oldValues return
			if(down && values.toString() === this.trimBarSlider.oldValues.toString()){
				return;
			}

			if(!down && (this.trimStart !== start || this.trimEnd !== end)){
				this.trimStart = start;
				this.trimEnd = end;
				events.emit('trimChange', {start, end});
			}

			let frameToDraw = cue;
			if(down){
				if(start !== this.trimBarSlider.oldValues[0]){
					frameToDraw = start;
				} else if(end !== this.trimBarSlider.oldValues[2]){
					frameToDraw = end;
				}
			}

			this.currentTimestamp = frameToDraw * this.frameDuration;
			this.drawFrame(frameToDraw);

			this.trimBarSlider.oldValues = values.slice();
		});

	}
}

export default CanvasVideoPlayer;
