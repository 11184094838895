import { FabricText } from 'fabric';

import data from '@emoji-mart/data'
import { Picker } from 'emoji-mart'

import globals from '../globals';
import { addDeleteControls } from '../utils/fabricDeleteControls';

const emojiButton:HTMLElement = document.querySelector('#emoji-button')!;
const emojiWidget:HTMLElement = document.querySelector('#emoji-widget')!;

function addEmojiToCanvas(emoji: string) {
  const text = new FabricText(emoji, {
    left: 100,
    top: 100,
    fontSize: 44,
    fontFamily: 'Arial',
    originX: 'center',
    originY: 'center',
  });

  addDeleteControls(text);

  globals.fabricCanvas!.add(text);
}

function showEmojiPicker(b:boolean){
  emojiWidget.style.display = b ? 'block' : 'none';

  if(b){
    function onClickOutside(e:MouseEvent){
      if(e.target !== emojiWidget && !emojiWidget.contains(e.target as Node)){
        showEmojiPicker(false);
        document.removeEventListener('pointerdown', onClickOutside);
      }
    }
    document.addEventListener('pointerdown', onClickOutside);
  }
}

export default function addEmojiPicker(){
	new Picker({
		parent: emojiWidget,
		data: data,
		emojiButtonRadius: '6px',
		theme: 'auto',
		onEmojiSelect: (emoji) => {
			addEmojiToCanvas(emoji.native);
			showEmojiPicker(false);
		},
	});
	emojiButton.addEventListener('click', (e) => {
		showEmojiPicker(true);
		e.stopPropagation();
	});
}

