import events from "./events";
import { decodeVideoToFrames } from "./utils/videoDecoder";

window.addEventListener("message", async event => {
	if (event.data.type !== 'poki/game/fetched_replay') {
		return;
	}

	const {video, fps, container, width, height} = event.data;

	console.log(event.data);

	const frames = await decodeVideoToFrames(event.data);
	// Only keep the last 30 seconds
	const keep = 30 * fps;
	if (frames.length > keep) {
		frames.splice(0, frames.length - keep);
	}

	events.emit('receiveVideo', { video, fps, frames, container, width, height });
});

// Kick off the fetch
window.opener?.postMessage({ type: 'fetchReplay' }, "*");
