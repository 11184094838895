export function serializeFrames(frames) {
	return frames.map(frame => ({
		...frame,
		colorSpace: {
			// Serialize the VideoColorSpace properties, since it can't be transferred directly
			fullRange: frame.colorSpace.fullRange,
			matrix: frame.colorSpace.matrix,
			primaries: frame.colorSpace.primaries,
			transfer: frame.colorSpace.transfer
		}
	}));
}

export function deserializeFrames(frames) {
  return frames.map(frame => ({
    ...frame,
    colorSpace: new VideoColorSpace({
      fullRange: frame.colorSpace.fullRange,
      matrix: frame.colorSpace.matrix,
      primaries: frame.colorSpace.primaries,
      transfer: frame.colorSpace.transfer
    })
  }));
}
