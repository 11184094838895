import Mp4Muxer from "../plugins/mp4muxer";

export async function encodeFramesToVideo(data){
	const { frames, fps, width: displayWidth, height: displayHeight } = data;

	let outputVideoWidth = displayWidth;
	let outputVideoHeight = displayHeight;

	const start = performance.now();

	// If it's bigger than 720p?
	if (outputVideoWidth > 1280 || outputVideoHeight > 720) {
		// Scale down to 720p.
		const scale = Math.min(1280 / outputVideoWidth, 720 / outputVideoHeight);
		outputVideoWidth = Math.round(outputVideoWidth * scale);
		outputVideoHeight = Math.round(outputVideoHeight * scale);
	}

	// Some browsers require even dimensions.
	if (outputVideoWidth % 2 !== 0) {
		outputVideoWidth++;
	}
	if (outputVideoHeight % 2 !== 0) {
		outputVideoHeight++;
	}

	if (outputVideoWidth !== displayWidth || outputVideoHeight !== displayHeight) {
		console.info(`%cPOKI_REPLAY:%c resizing video from ${displayWidth}x${displayHeight} to ${outputVideoWidth}x${outputVideoHeight}`, 'font-weight: bold', '');
	}

	let muxer = new Mp4Muxer.Muxer({
		target: new Mp4Muxer.ArrayBufferTarget(),
		video: {
			codec: 'avc',
			width: outputVideoWidth,
			height: outputVideoHeight,
		},
		fastStart: 'in-memory',
		firstTimestampBehavior: 'offset',
	});

	let videoEncoder = new VideoEncoder({
		output: (chunk, meta) => {
			muxer.addVideoChunk(chunk, meta);
		},
		error: e => console.error('%cPOKI_REPLAY:%c error encoding video', 'font-weight: bold', '', e),
	});
	videoEncoder.configure({
		codec: 'avc1.42001f',
		width: outputVideoWidth,
		height: outputVideoHeight,
		bitrate: 1e6, // TODO: pick based on quality we want vs file size.
		framerate: fps,
	});

	let lastKeyframe = -1;

	for (let i = 0; i < frames.length; i++) {
		const frame = new VideoFrame(frames[i].buffer, frames[i]);

		let needsKeyFrame = frame.timestamp > lastKeyframe + (1 * 1e6); // Every second.
		if (needsKeyFrame) {
			lastKeyframe = frame.timestamp;
		}

		videoEncoder.encode(frame, { keyFrame: needsKeyFrame });

		frame.close();
	}

	await videoEncoder.flush();
	videoEncoder.close();
	muxer.finalize();

	let { buffer } = muxer.target;

	console.info(`%cPOKI_REPLAY:%c encoding took ${Math.round(performance.now() - start)} ms`, 'font-weight: bold', '');

	const blob = new Blob([buffer], { type: 'video/mp4' });

	return blob;
}
