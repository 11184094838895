import * as fabric from 'fabric';

export default {
	videoFrames:[],
	bakedVideoFrames: new Promise(() => {}),
	fabricCanvas: null,
	fabricCanvasDirty: false,
	trimStart: 0,
	trimEnd: 0,
	videoAspectRatio: 0,
} as {
	videoFrames: any[],
	bakedVideoFrames: Promise<any> | null,
	fabricCanvas: fabric.Canvas | null,
	fabricCanvasDirty: boolean,
	trimStart: number,
	trimEnd: number,
	videoAspectRatio: number,
}
